<template>
  <div class="bg-white">
    <!-- Mobile menu -->
    <!-- Hero section -->
    <div class="relative bg-gray-900">
      <!-- Decorative image and overlay -->
      <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
        <img
          :src="grads.Header_Image"
          alt=""
          class="w-full h-full object-center object-cover"
          data-aos="fade-in"
        />
      </div>
      <div aria-hidden="true" class="absolute inset-0 bg-black opacity-70" />

      <div
        class="relative max-w-3xl mx-auto py-16 px-6 flex flex-col items-center text-center sm:py-64 lg:px-0"
      >
        <h1
          class="text-6xl font-extrabold tracking-tight text-white lg:text-6xl"
          data-aos="fade-up"
        >
          Book your<span class="text-G"> Graduation</span>.
        </h1>

        <router-link
          to="/contact"
          class="mt-8 inline-block bg-G border border-transparent rounded-md py-3 px-8 text-base font-medium text-white hover:bg-yellow-700"
          data-aos="fade-up"
          >Fill in form</router-link
        >
      </div>
    </div>

    <main>
      <!-- Category section -->

      <!-- Featured section -->
      <section
        aria-labelledby="social-impact-heading"
        class="max-w-7xl mx-auto pt-24 px-4 sm:pt-32 sm:px-6 lg:px-8"
      >
        <div class="relative shadow-md rounded-lg overflow-hidden">
          <div class="absolute inset-0">
            <img
              :src="grads.Background_Image"
              alt=""
              class="w-full h-full object-center object-cover"
            />
          </div>
          <div
            class="relative bg-gray-900 bg-opacity-75 py-32 px-6 sm:py-40 sm:px-12 lg:px-16"
          >
            <div
              class="relative max-w-3xl mx-auto flex flex-col items-center text-center"
            >
              <h2
                id="social-impact-heading"
                class="text-4xl font-extrabold tracking-tight text-G sm:text-4xl"
              >
                {{ grads.Title }}
              </h2>
              <p class="mt-3 block text-md font-medium text-white">
                {{ grads.Paragraph_Part_1 }}
              </p>
              <p class="mt-3 block text-md font-medium text-white">
                {{ grads.Paragraph_Part_2 }}
              </p>
              <p class="mt-3 block text-md font-medium text-white">
                {{ grads.Paragraph_Part_3 }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Collection section -->
      <section
        aria-labelledby="collection-heading"
        class="max-w-xl mx-auto pt-24 px-4 sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8"
      >
        <h2
          id="collection-heading"
          class="text-4xl font-extrabold tracking-tight text-gray-900"
        >
          Gallery
        </h2>

        <div
          class="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8"
        >
          <div
            v-for="collection in collections"
            :key="collection.name"
            :href="collection.href"
            class="group block"
          >
            <div
              aria-hidden="true"
              class="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden lg:aspect-w-5 lg:aspect-h-6"
            >
              <img
                :src="collection.imageSrc"
                :alt="collection.imageAlt"
                class="w-full h-full object-center object-cover shadow-md"
                data-aos="fade-up"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- Featured section -->
      <section
        aria-labelledby="comfort-heading"
        class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8"
      >
        <h2
          id="collection-heading"
          class="text-4xl font-extrabold tracking-tight text-gray-900"
        >
          Graduations After Movie.
        </h2>

        <div class="mt-10 relative rounded-lg overflow-hidden">
          <div class="absolute inset-0"></div>
          <div class="relative bg-gray-900 bg-opacity-75">
            <div class="mx-auto flex flex-col items-center text-center">
              <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/0tt0fxysZP4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// API SCRIPT
import axios from "axios";

export default {
  data() {
    return {
      grads: {
        Header_Image: "",
        Background_Image: "",
        Title: "",
        Paragraph_Part_1: "",
        Paragraph_Part_2: "",
        Paragraph_Part_3: "",
      },
    };
  },
  methods: {
    getGrads() {
      let __this = this;
      axios
        .get(
          "https://api.storyblok.com/v2/cdn/stories/graduations?cv=1661664358&token=VJUdfvoIDyOFIfXpRVZ08Qtt&version=published"
        )
        .then((response) => {
          let gradsContent = response.data["story"]["content"];
          __this.grads = {
            Header_Image: gradsContent["Header_Image"]["filename"],
            Background_Image: gradsContent["Background_Image"]["filename"],
            Title: gradsContent["Title"],
            Paragraph_Part_1: gradsContent["Paragraph_part_1"],
            Paragraph_Part_2: gradsContent["Paragraph_part_2"],
            Paragraph_Part_3: gradsContent["Paragraph_part_3"],
          };
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.getGrads();
  },
};
</script>

<script setup>
import im1 from "../assets/G/11.jpeg";
import im2 from "../assets/G/23.jpeg";
import im3 from "../assets/G/13.jpeg";

const collections = [
  {
    imageSrc: im1,
  },
  {
    imageSrc: im2,
  },
  {
    imageSrc: im3,
  },
];
</script>
